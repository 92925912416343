import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import { Navbar, Nav } from 'react-bootstrap'

const Header = ({ siteTitle, logo }) => (
  <header>
    <Navbar expand='lg' bg='light'>
      <Navbar.Brand>
        <Link to='/'>
          <Img fixed={logo.childImageSharp.fixed} />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='ml-auto'>
          <Link to='/politik' className='nav-link'>
            Politik
          </Link>
          {/* <Link to='/wahl2020' className='nav-link'>Wahl2020</Link> */}
          {/* <Link to='/ortsverband' className='nav-link'>Ortsverband</Link> */}
          <Link to='/unserestadträte' className='nav-link'>
            Unsere Stadträte
          </Link>
          <Link to='/mitmachen' className='nav-link'>
            Mitmachen
          </Link>
          <Link to='/unterstützen' className='nav-link'>
            Unterstützen
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
