/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Navbar, Container, Row, Col } from 'react-bootstrap'

import Header from './header'
import './layout.css'

import '../css/bootstrap.min.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "csu-logo.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} logo={data.logo} />
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}> */}
      <main>{children}</main>
      <footer>
        <Navbar bg='dark'>
          <Container>
            <Row className='text-light' style={{ width: '100%' }}>
              <Col>© {new Date().getFullYear()}, CSU Neutraubling</Col>
              <Col style={{ textAlign: 'right' }}>
                <Link to='/kontakt' className='text-light'>
                  Kontakt
                </Link>{' '}
                |{' '}
                <Link to='/impressum' className='text-light'>
                  Impressum
                </Link>{' '}
                |{' '}
                <Link to='/datenschutz' className='text-light'>
                  Datenschutz
                </Link>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </footer>
      {/* </div> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
